import { getCountry, getWithDotComByCountry } from '../utils';

const country = getCountry();
const withCom = getWithDotComByCountry(country);

export const env = {
  NODE_ENV: 'production',
  URL: {
    MANUAL_URL: `https://manual.neogig${withCom}${country}/docs`,
    IDENTITY_SERVER_URL: `https://identity-server.neogig${withCom}${country}`,
  },
};
